<template>
  <section class="section container section-icons white center">
    <div class="container">
      <div class="row">
        <div class="col s6 m4 offset-m2">
          <div class="card-panel grey lighten-4">
            <img class="pablo-logo" src="/img/pcerino-logo.png" />
          </div>
        </div>
        <div class="col s6 m4">
          <div class="card-panel grey lighten-4">
            <img src="/img/icon-logo2.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
img {
  width: 100%;
}
section {
  margin-top: 20px;
}
.pablo-logo {
  filter: contrast(1) brightness(0);
}
</style>
