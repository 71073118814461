<template>
<div></div>
  <!-- <form action="/send-email" method="POST">
    <div class="row">
      <div class="input-field col s6 m6">
        <input
          id="first_name"
          name="first_name"
          type="text"
          class="white-text validate"
        />
        <label for="first_name">First Name</label>
      </div>
      <div class="input-field col s6 m6">
        <input
          id="last_name"
          name="last_name"
          type="text"
          class="white-text validate"
        />
        <label for="last_name">Last Name</label>
      </div>
      <div class="input-field col s12 m12">
        <input
          id="email"
          name="email"
          type="email"
          class="white-text validate"
        />
        <label for="email">Email</label>
      </div>
      <div class="input-field col s12">
        <textarea
          name="content"
          id="content"
          cols="30"
          rows="10"
          class="materialize-textarea white-text"
        ></textarea>
        <label for="content">Content</label>
      </div>
      <div
        style="
          background-color: rgba(255, 126, 126, 0.541);
          border-radius: 10px;
        "
        class="center-align col s12"
      ></div>
      <div style="margin-top: 10px" class="col s6 offset-s2 m5 offset-m3">
        <button
          type="submit"
          class="center-align orange darken-1 btn waves-effect waves-light"
        >
          Send Email
        </button>
      </div>
    </div>
  </form> -->
</template>
