<template>
  <section class="section section-icons grey lighten-4 center">
    <div class="container">
      <div class="row">
        <div class="col s12 m4">
          <div class="card-panel">
            <i class="material-icons large orange-text">fitness_center</i>
            <h6 class="header">Great For Exercising</h6>
          </div>
        </div>
        <div class="col s12 m4">
          <div class="card-panel">
            <i class="material-icons large orange-text">group</i>
            <h6 class="header">Great For Social Networking</h6>
          </div>
        </div>
        <div class="col s12 m4">
          <div class="card-panel">
            <i class="material-icons large orange-text">self_improvement</i>
            <h6 class="header">Great For Self Improvement</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<style scoped>
section {
  max-width: 80%;
  margin: auto;
}
.row {
  margin-bottom: 0;
}
</style>